import React from 'react';

import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import { HttpStatus } from '@ha/api/v2/handleApiV2Response';

import { ErrorMessages } from 'ha/constants/ErrorMessages';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { useServices } from 'ha/services';
import { DefaultTimezone, getTimezone } from 'ha/utils/getTimezone';
import { isEmailCorrect } from 'ha/utils/isEmailCorrect';

import { useShowAlert, AlertType } from 'ha/modules/AlertBar/helpers/ShowAlert';
import { getSearchAlertParams } from 'ha/pages/Search/selectors/searchAlertSelectors';

import { PageDrawer } from '../../../../SearchRedesign/components/PageDrawer/PageDrawer';

import { SearchAlertsForm } from './SearchAlertsForm';
import { SearchAlertsSubmitSuccess } from './SearchAlertsSubmitSuccess';
import {
  FormValues,
  SearchAlertsExperimentProps,
  FormErrorKeys,
} from './types';

export const SearchAlertsDrawerWithoutAuth = ({
  open,
  onClose,
}: Omit<SearchAlertsExperimentProps, 'form'>) => {
  const { bellV2 } = useServices();
  const searchAlertParams = useSelector(getSearchAlertParams);
  const showAlert = useShowAlert();

  const handleSubmit = async (values: FormValues) => {
    try {
      await bellV2.createSearchAlert({
        ...searchAlertParams,
        channel: 'email',
        frequency: 'daily',
        timezone: getTimezone() || DefaultTimezone,
        email: values.email,
      });

      return null;
    } catch (error) {
      if (error?.code === HttpStatus.FORBIDDEN) {
        return { [FORM_ERROR]: FormErrorKeys.EMAIL_ALREADY_USED };
      }

      reportError(
        new Error('createSearchAlert without auth failed', { cause: error }),
      );

      showAlert({
        kind: AlertType.Danger,
        text: ErrorMessages.something_went_wrong,
      });

      return { [FORM_ERROR]: ErrorMessages.something_went_wrong };
    }
  };

  const validate = (values: FormValues) => {
    const errors = { email: '' };

    if (!isEmailCorrect(values.email)) {
      errors.email = FormErrorKeys.INVALID_EMAIL;
      return errors;
    }

    return {};
  };

  return (
    <Form<FormValues>
      initialValues={{ email: '' }}
      validate={validate}
      onSubmit={async values => {
        const submitResp = await handleSubmit(values);
        return submitResp;
      }}
    >
      {formProps => {
        return (
          <PageDrawer
            open={open}
            onClose={onClose}
            testLocator="SearchAlertsDrawerWithoutAuth"
          >
            {formProps.submitSucceeded ? (
              <SearchAlertsSubmitSuccess form={formProps} />
            ) : (
              <SearchAlertsForm form={formProps} />
            )}
          </PageDrawer>
        );
      }}
    </Form>
  );
};

// eslint-disable-next-line import/no-default-export
export default SearchAlertsDrawerWithoutAuth;
